.rs-panels-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: var(--space-s);
    margin-block-end: var(--space-l);
}

.rs-panel-projects {
    margin-block-start: var(--space-xl);
    margin-block-end: var(--space-s);

}